const styles = ({ palette, typography }) => ({
  card: {
    margin: typography.pxToRem(16),
    padding: typography.pxToRem(8),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 12px 60px 0 rgba(0,0,0,.11)',
  },
  cardContent: {
    flexGrow: 1,
  },
});

export default styles;
