const content = [
  // {
  //   question: `Who should join?`,
  //   answer: `If you’re looking for a family doctor,
  //   and you would like to become a patient at HealthPoint Medical Clinic,
  //   please fill the online application form. The form is HIPPA compliant,
  //   and any information submitted through the form is encrypted`,
  //   button: {
  //     enabled: true,
  //     text: 'apply now',
  //     href: 'https://hipaa.jotform.com/93100721895254',
  //   },
  // },
  {
    question: `Are you accepting new patients?`,
    answer: `Unfortunately we recently reached our capacity and are unable to take anymore applications at this time.
    We will reopen the application in the future if space opens up. We apologize for the inconvenience.`,
    button: {
      enabled: false,
      text: '',
      href: '',
    },
  },
  {
    question: `I applied but still haven't heard back?`,
    answer: `We received a large number of applications, and we are currently working on processing them as fast as 
    possible. If you applied recently you should hear from us in 4 to 6 weeks. It takes time for the doctors to review
    the applications and schedule the first visit on top of their regular duties. We appreciate your patience in the time being.`,
    button: {
      enabled: false,
      text: '',
      href: '',
    },
  },
  // {
  //   question: `What happens next?`,
  //   answer: `After we process your application,
  //   you will receive a phone call or email from our office,
  //   asking you to come for a “Meet & Greet” visit. 
  //   Your physician will introduce themselves, their values and scope of practice,
  //   and will ask you some questions to understand your health and lifestyle better.`,
  //   button: {
  //     enabled: false,
  //     text: '',
  //     href: '',
  //   },
  // },
  // {
  //   question: `Can I apply for other family members?`,
  //   answer: `If you’re applying for other family members,
  //   kindly fill a separate form for each individual.`,
  //   button: {
  //     enabled: false,
  //     text: '',
  //     href: '',
  //   },
  // },
  // {
  //   question: `Can I have multiple family doctors?`,
  //   answer: `No. If you currently have a family doctor,
  //   please register with us only if you intend to transfer from your current family physician
  //   (i.e. leave your current family physician)`,
  //   button: {
  //     enabled: false,
  //     text: '',
  //     href: '',
  //   },
  // },
  {
    question: `Can I transfer from a Healthpoint doctor to another?`,
    answer: `If you already have a family doctor at HealthPoint Medical Clinic,
    unfortunately you cannot switch to another HealthPoint physician.`,
    button: {
      enabled: false,
      text: '',
      href: '',
    },
  },
];

export default content;
