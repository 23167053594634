import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';
const useStyles = makeStyles(() => ({
  tCell: {
    border: 'none',
  },
}));
export default function Hours() {
  const classes = useStyles();
  const TCell = ({ align, text }) => (
    <TableCell className={classes.tCell} align={align} padding='none'>
      <Typography
        variant='subtitle2'
        align='left'
        color='secondary'
        display='inline'>
        {text}
      </Typography>
    </TableCell>
  );

  const {
    googlePlacesPlace: {
      opening_hours: { weekday_text },
    },
  } = useStaticQuery(graphql`
    query {
      googlePlacesPlace {
        opening_hours {
          weekday_text
        }
      }
    }
  `);

  return (
    <React.Fragment>
      <Typography variant='h6'>BUSINESS HOURS</Typography>
      <Table>
        <TableBody>
          {weekday_text.map((day, index) => {
            let openingHours = day.split(': ');

            return (
              <TableRow key={index}>
                <TCell
                  align='left'
                  text={`${openingHours[0].substring(0, 3)}`}
                />
                <TCell align='left' text={`:\xa0`} />
                <TCell align='justify' text={openingHours[1]} />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
