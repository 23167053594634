import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { CustomButton } from 'components';
import links from './content';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Dialog, DialogContent, Typography } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  text: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));
export default function CenteredGrid() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }
  return (
    <Grid
      container
      direction='row'
      justify='space-evenly'
      alignItems='center'
      spacing={2}>
      {links.map(({ to, text, Icon, alt, isLocal, openModal }, index) => (
        <Grid item key={index} xs={12} sm={6}>
          <CustomButton
            text={text}
            to={to}
            icon={Icon}
            alt={alt}
            isLocal={isLocal}
            clickHandler={openModal ? handleClickOpen : null}
          />
        </Grid>
      ))}
    </Grid>
  );
}
