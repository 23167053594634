import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Fade } from '@material-ui/core';
import { Animated, FAQList } from 'components';
import content from './content';
import NewPatientPageBg from '../../images/NewPatientPageBg.svg';

const useStyles = makeStyles(theme => ({
  svgImg: {
    width: '100%',
    height: '100%',
    display: 'block',
  },
  cardGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export default function NewPatientsInfo() {
  const classes = useStyles();

  return (
    <>
      <Fade in timeout={1000}>
        <Typography variant='h1' color='textPrimary'>
          <Animated>New patient information</Animated>
        </Typography>
      </Fade>

      <Container maxWidth='sm'>
        <img src={NewPatientPageBg} className={classes.svgImg} />
      </Container>
      <br />
      <Container maxWidth='lg' className={classes.cardGrid}>
        <FAQList faq={content} />
      </Container>
    </>
  );
}
