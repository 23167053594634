import { FaGithub } from 'react-icons/fa';
const content = {
   title: 'Our team.',
   subtitle: 'Compassion is at the heart of our care.',
   team: [
    {
      name: 'Dr. Michael Rath',
      creds: 'MD, CM, CCFP, FCFP',
      about: `Dr. Rath was born and raised in Montreal and received his
      medical degree from McGill University. He is a Fellow of
      the Canadian College of Family Practice and a Clinical
      Instructor in the Faculty of Medicine at the University of
      British Columbia. He brings 40 years of experience as a
      physician, including 26 years in Langley. His practice is
      now focused on chronic disease management and prevention
      as well as treating acute problems.`,
    },
    {
      name: 'Dr. Thomas D. Parsons',
      creds: 'MD, CCFP, FCFP',
      about: `Dr. Parsons was born Newfoundland and raised in the
      maritimes graduated from Queen’s University. Over the last
      48 years, Dr. Parsons has helped the many communities he
      has lived in, from covering emergencies, delivering babies
      to working in an acute hospital environment. While at
      Langley Memorial Hospital, he held the position of Chief
      of Staff along with President of Medical Staff. He
      now works at HealthPoint Medical Clinic, where he is using
      his many years of experience to continue his Family
      Practice in the community he has called home for more than
      40 years.`,
    },
    {
      name: 'Alison Lark',
      creds: 'Medical office administrator',
      about: `Alison was born and raised in Cloverdale, BC. She dove
      straight into the work force, and after sharing her
      passion for music and entertainment with everyone she
      helped, she decided she wanted to do more. While working
      as an MOA, Alison met Dr.Parsons, and the rest is history.
      Fast forward 10 years, 25 half marathons, 5 full
      marathons, and a recent love for cruises, Alison now calls
      HealthPoint Medical Clinic home. Alison is always ready to
      help patients when they come through the doors, and dont
      be shy to show her pictures of your dogs on your phone.`,
    },
   ],
 };

export default content;