import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import styles from './style';

type Props = {
  classes: Object,
  list: [],
};

const LinkList = ({ classes, list }: Props) => (
  <>
    {list.map(({ name, creds, about }, index) => (
      <Card key={index} className={classes.card} elevation={3}>
        <CardHeader
          disableTypography={true}
          title={
            <Typography variant='h5'>
              {name}&nbsp;
              <Typography
                variant='subtitle1'
                color='textSecondary'
                display='inline'>
                {creds}
              </Typography>
            </Typography>
          }
          titleTypographyProps={{ align: 'left' }}
        />
        <CardContent className={classes.cardContent}>
          <Typography
            variant='body2'
            paragraph={true}
            align='justify'
            color='textPrimary'>
            {about}
          </Typography>
        </CardContent>
      </Card>
    ))}
  </>
);

export default withStyles(styles)(LinkList);
