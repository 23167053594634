import React from 'react';
import { contact } from './content';
import { Typography } from '@material-ui/core';
const { title, links } = contact;
const Contact = ({ classes }) => (
  <React.Fragment>
    <Typography variant='h6' display='block' align='left'>
      {title}
    </Typography>
    {links.map(({ to, text, Icon, newTab, isLocal }, index) => (
      <Typography
        key={index}
        component='a'
        display='block'
        variant='button'
        href={to}
        target={newTab ? '_blank' : '_self'}
        rel={isLocal ? '' : 'noopener noreferrer'}
        className={classes.link}>
        <Icon className={classes.icon} />
        {text}
      </Typography>
    ))}
  </React.Fragment>
);
export default Contact;
