import React from 'react';
import { Typography, Fade, Container, Card } from '@material-ui/core';
import { Animated } from 'components';
import { ButtonGrid } from 'containers';
import content from './content';
import FeatureImg from '../../images/featureImg.svg';

const { tagline, title, subtitle, message } = content;

const Home = () => (
  <Container maxWidth='md'>
    <Typography variant='h3' color='textPrimary'>
      {tagline}
    </Typography>
    <Fade in timeout={1000}>
      <Typography variant='h1' color='textPrimary'>
        <Animated>{title}</Animated>
      </Typography>
    </Fade>
    <Typography variant='h3' color='textPrimary'>
      {subtitle}
    </Typography>
    <img src={FeatureImg} alt='Feature Image' />
    <Typography variant='h3' color='textPrimary'>
       {message}
     </Typography>
     <br />
    <ButtonGrid />
  </Container>
);

export default Home;
