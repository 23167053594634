const styles = ({ typography }) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 12px 60px 0 rgba(0,0,0,.11)',
  },
  cardBtn: {
    marginLeft: typography.pxToRem(8),
  },
});

export default styles;
