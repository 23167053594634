import CalendarImg from '../../images/calendar.svg';
import CancelImg from '../../images/cancel-thin.svg';
import ClipboardImg from '../../images/clipboard.svg';
import StethoscopeImg from '../../images/stethoscope.svg';
const links = [
  {
    to: 'https://healthpoint.cortico.ca/book/family-doctor/',
    text: 'Book with your family doctor',
    Icon: StethoscopeImg,
    alt: 'Stethoscope icon',
    newTab: false,
    isLocal: false,
    openModal: false,
  },
  {
    to: 'https://healthpoint.cortico.ca/get-patient-appointment-lookup-url/',
    text: 'Cancel booked appointment',
    Icon: CancelImg,
    alt: 'Cancel icon',
    newTab: false,
    isLocal: false,
    openModal: false,
  },
  {
    to: '/new-patients/',
    text: 'New Patients information',
    Icon: ClipboardImg,
    alt: 'Clipboard icon',
    newTab: false,
    isLocal: true,
    openModal: false,
  },
];
export default links;
