import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styles from './style';

type Props = {
  classes: Object,
  faq: List,
};

const FaqList = ({ classes, faq }: Props) => (
  <Grid container spacing={2}>
    {faq.map(({ question, answer, button }, index) => (
      <Grid item key={index} xs={12} sm={6}>
        <Card className={classes.card} elevation={2}>
          <CardContent>
            <Typography variant='h5' component='h2' color='textPrimary'>
              {question}
            </Typography>
            <Typography variant='body1' color='textSecondary'>
              <br />
              {answer}
            </Typography>
          </CardContent>
          {button.enabled == true ? (
            <CardActions className={classes.cardBtn}>
              <Button
                size='small'
                href={button.href}
                target='blank'
                variant='contained'
                color='primary'
                component={OutboundLink}>
                {button.text}
              </Button>
            </CardActions>
          ) : null}
        </Card>
      </Grid>
    ))}
  </Grid>
);

export default withStyles(styles)(FaqList);
