import React from 'react';
import Link from 'gatsby-link';
import { makeStyles } from '@material-ui/core/styles';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import {
  Button,
  ButtonBase,
  Card,
  CardMedia,
  CardActionArea,
  CardActions,
  Typography,
  CardContent,
  Grid,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 1,
  },
  image: {
    width: '50px',
    height: '50px',
  },
  textArea: {
    flexShrink: 5,
  },
}));

export default function CustomButton({
  to,
  text,
  icon,
  alt,
  isLocal,
  clickHandler,
}) {
  const classes = useStyles();

  return (
    <Button
      fullWidth={true}
      size='large'
      variant='contained'
      color='secondary'
      component={isLocal ? Link : OutboundLink}
      to={isLocal ? to : '#'}
      href={isLocal ? '#' : to}
      onClick={clickHandler}>
      <Grid
        container
        direction='row'
        alignItems='center'
        justify='center'
        spacing={1}>
        <Grid item>
          <img className={classes.image} src={icon} alt={alt} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant='body1'>{text}</Typography>
        </Grid>
      </Grid>
    </Button>
  );
}
