import React from 'react';
import { Link, Typography } from '@material-ui/core';

export default function Copyright() {
  return (
    <React.Fragment>
      <Typography variant='caption' align='left'>
        {'Copyright © '}
        <Link color='inherit' href='https://hpmedical.ca/'>
          HealthPoint Family Practice
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
        {' Built with '}
        ❤️
      </Typography>
    </React.Fragment>
  );
}
