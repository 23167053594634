import React, { useState } from 'react';
import Link from 'gatsby-link';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { withStyles } from '@material-ui/styles';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import Logo from '../../images/logo.svg';
import styles from './style';
import links from './links';
const { navLinks, dropdownLinks } = links;

type Props = {
  classes: Object,
};

const Example = ({ classes }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const getLocalLink = (to, text, index) => (
    <NavLink className={classes.navLink} key={index} tag={Link} to={to}>
      {text}
    </NavLink>
  );
  const getExternalLink = (to, text, index) => (
    <NavLink key={index} href={to} tag={OutboundLink}>
      {text}
    </NavLink>
  );

  return (
    <div className={classes.container}>
      <Navbar color='light' light expand='md'>
        <NavbarBrand className={classes.logo} tag='img' src={Logo} alt='logo' />
        <NavbarToggler onClick={toggle} className={classes.toggler} />
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar className={classes.nav}>
            {navLinks.map(({ to, text, isLocal }, index) =>
              isLocal
                ? getLocalLink(to, text, index)
                : getExternalLink(to, text, index)
            )}
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Family Patient Bookings
              </DropdownToggle>
              <DropdownMenu className={classes.menu} right>
                {dropdownLinks.map(({ to, text }, index) => (
                  <DropdownItem key={index} tag={NavLink} href={to}>
                    {text}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default withStyles(styles)(Example);
