const links = {
  navLinks: [
    { to: '/', text: 'Home', isLocal: true },
    { to: '/new-patients/', text: 'New Patients', isLocal: true },
    { to: '/about/', text: 'About Us', isLocal: true },
  ],
  dropdownLinks: [
    {
      to: 'https://healthpoint.cortico.ca/book/family-doctor/',
      text: 'Book an appointment',
      isLocal: false,
    },
    {
      to: 'https://healthpoint.cortico.ca/get-patient-appointment-lookup-url/',
      text: 'Cancel appointment',
      isLocal: false,
    },
  ],
};

export default links;
