import theme from 'styles/theme';

const styles = ({ palette, typography }) => {
  return {
    container: {
      backgroundColor: palette.primary.main,
      display: 'flex',
      margin: `${typography.pxToRem(8)} auto 0`,
      padding: theme.spacing(4),
      paddingTop: theme.spacing(8),
      width: '100%',
      color: palette.primary.contrastText,
    },
    link: {
      display: 'block',
      textDecoration: 'none',
      textAlign: 'left',
      color: palette.primary.contrastText,
      whiteSpace: 'pre-wrap',
      '&:hover': {
        textDecoration: 'none',
        color: '#FFD046',
      },
    },
    icon: {
      marginRight: typography.pxToRem(8),
    },
  };
};

export default styles;
