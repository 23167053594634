const styles = ({ app, typography }) => ({
  container: {
    width: '100%',
    display: 'block',
    margin: 'auto',
  },
  nav: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  },

  logo: {
    display: 'inline-block',
    border: 'none',
    float: 'left',
    transition: 'all .3s',
    textDecoration: 'none',
    width: '140px',
    margin: `0 0 ${typography.pxToRem(8)} 0`,
  },
  navLink: {
    display: 'inline-block',
  },
  toggler: {
    margin: `0 ${typography.pxToRem(16)} 0 0`,
  },
  menu: {
    width: '90%',
    margin: `${typography.pxToRem(16)}`,
  },
});

export default styles;
