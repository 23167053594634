import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Typography, Grid, Container } from '@material-ui/core';
import { Contact, Copyright, Hours } from './components';
import styles from './style';

type Props = {
  classes: Object,
};

const Footer = ({ classes }: Props) => (
  <footer className={classes.container}>
    <Container maxWidth='md'>
      <Grid
        container
        direction='row'
        justify='space-between'
        alignItems='flex-start'>
        <Grid item>
          <Contact classes={classes} />
        </Grid>
        <Grid item>
          <Hours />
        </Grid>
      </Grid>
      <Copyright />
    </Container>
  </footer>
);

export default withStyles(styles)(Footer);
