import React from 'react';
import { withStyles } from '@material-ui/styles';

import { NavigationBar } from 'components';

import styles from './style';

type Props = {
  classes: Object,
};

const Header = ({ classes }: Props) => (
  <header className={classes.container}>
    <NavigationBar />
  </header>
);

export default withStyles(styles)(Header);
