import theme from 'styles/theme';
const styles = ({ typography }) => ({
  container: {
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
    display: 'block',
    marginBottom: `${typography.pxToRem(8)}`,
    background: `#f8f9fa`,
    width: '100%',
  },
});

export default styles;
